@import "./../../common/styles";

@font-face {
  font-family: "LingHei";
  src: url(../fonts/MFLingHei.ttf);
}

body {
  min-width: 1200px;
  margin: 0;
  padding: 0;
  font-family: Arial;
  overflow: hidden;
  overflow-y: auto;
  background-size: 100% 100%;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

body.ready .body-wrapper {
  opacity: 1;
}

a {
  text-decoration: none;
  i {
    text-decoration: none;
    color: #e09e01;
  }

  &.color-link {
    color: var(--primaryColor);
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

p {
  margin: 0;
  padding-bottom: 7px;
}

table,
th {
  border: none;
  padding: 10px;
}

table,
td {
  border: none;
  padding: 10px;
  background-color: #f1f1f1;
}

.main-wrapper {
  width: 1000px;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
}

/*Standard buttons starts from here*/

.button {
  background-image: linear-gradient(#19628b, #4894be);
  color: #fff;
  cursor: pointer;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 28px;
  line-height: 28px;
  margin-left: 5px;
  min-width: 48px;
  outline: none;
  padding: 0 10px;
  position: relative;
  text-align: center;
  transition: all 200ms ease-in-out;
  vertical-align: top;
  &:hover {
    background-image: linear-gradient(#113768, #19628b);
  }

  &.btn-afflogin {
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    width: 150px;
    margin-top: 10px;
  }

  &.btn-login {
    background: #ffec57;
    color: #19628b;
    cursor: pointer;
    &:hover {
      background: #f3cf00;
    }
  }
  &.btn-outline {
    background: transparent;
    border: 1px solid #ffec57;
    color: #ffec57;
    &:hover {
      border-color: #f3cf00;
      color: #f3cf00;
    }
  }
}

/*Top header starts from here*/

.top-header {
  font-size: 12px;
  line-height: 40px;
  height: 40px;
  background-image: linear-gradient(#4894be, #4185ab);
  color: #c8dfeb;
  display: flex;
  align-items: center;

  .main-wrapper {
    display: flex;
    align-items: center;
  }
}

.dtime-wrapper {
  height: 40px;
  line-height: 40px;
  font-size: 10pt;
  display: flex;
  align-items: center;
}

.datetime {
  color: #c8dfeb;
  margin-right: 15px;
}

.userlogin {
  margin-left: auto;
  height: 40px;
  line-height: 30px;
  font-size: 10.5pt;
  display: flex;
  align-items: center;
  input {
    background-color: #fff;
    border: 1px solid #b5e8ff;
    border-radius: 4px;
    outline: none;
    font-size: 10pt;
    padding: 0 0 0 9px;
    box-sizing: border-box;
    -webkit-appearance: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 28px;
  }

  &.after-login {
    a {
      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }
}

.ipt-username,
.ipt-password,
.ipt-vcode {
  position: relative;
  display: inline-block;
  height: 28px;
  width: 115px;
  overflow: hidden;
  margin-left: 5px;
  .verify-code {
    border-radius: 4px;
    height: 21px;
    width: 50px;
    position: absolute;
    top: 3px;
    right: 2px;
  }
}

span.divider {
  color: #ffec57;
  padding-left: 10px;
  padding-right: 5px;
}

.ipt-logged {
  margin-left: 5px;
  color: #ffec57;
}

.forgot-button {
  color: #4894be;
  line-height: 15px;
  position: absolute;
  display: inline-block;
  top: 7px;
  right: 8px;
  font-size: 13px;
  transition: all 200ms ease-in-out;
  &:hover {
    color: #ffec57;
  }
}

.logo {
  > a {
    height: 100%;
    display: flex;
    align-items: center;
  }
  img {
    width: 100%;
    height: 85%;
  }
}

/*Main Menu starts from here*/

.navigation {
  height: 85px;
  width: 100%;
  background-image: linear-gradient(#4894be, #19628b);
  .main-wrapper {
    display: flex;
    align-items: center;
  }
}

.main-menu {
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 85px;
  font-size: 12pt;

  .menu-links {
    color: #fff;
    display: inline-flex;
    align-items: center;
    margin: 0 18px;
    line-height: 30px;
    text-align: center;
    transition: all 100ms ease-in-out;
    position: relative;
    &:hover,
    &.active {
      color: #ffec57;
    }
    &:last-child {
      margin-right: 0;
    }

    &:not(:first-child):before {
      box-shadow: 4px 0 5px #245b7d;
      content: "";
      height: 100%;
      width: 1px;
      pointer-events: none;
      position: absolute;
      left: -20px;
    }
  }
}

/*Home banner starts from here*/

.main-banner {
  height: 400px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .swiper-container {
    width: 100%;
    height: 400px;
    /*min-width: 1048px;*/
    .swiper-pagination.banner {
      bottom: 5px;
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background-color: #ffec57;
        }
        background-color: #000;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.swiper-slide {
  overflow: hidden;
  .img-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: blur(10px) grayscale(50%);
    z-index: 1;
  }
  img {
    width: auto;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
  }
}

/*Subpage banner starts from here*/

.sub-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 360px;
  width: 40%;
}

.sub-cn {
  color: #19628b;
  font-size: 28px;
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.sub-en {
  color: #333333;
  font-size: 12pt;
  display: block;
}

/*News starts from here*/

.news-wrapper {
  color: #4c4c4c;
  margin-top: 7px;

  .main-wrapper {
    background-image: linear-gradient(#fbe5b7, #fdf2db);
    border-radius: 8px;
    box-shadow: 0 3px #fbe6bb;
    display: flex;
    align-items: center;
    height: 35px;
    padding-top: 4px;
  }

  .news-icon {
    padding: 0 10px;
    img {
      width: 35px;
    }
  }

  .marquee-wrapper {
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    width: 100%;
    vertical-align: middle;
    height: 100%;

    .news {
      white-space: nowrap;
      display: inline-block;
      &:hover {
        animation-play-state: paused;
      }
      p {
        font-size: 13px;
        margin: 0;
        display: inline-block;
        padding: 0 10px;
      }
    }
  }
}

/* 热门彩种 */
.games-wrapper {
  background: url("../img/game_bg.png") bottom / contain no-repeat;
  margin: 30px 0;

  .main-wrapper {
    display: flex;
  }

  .desc-wrapper {
    color: #707070;
    font-size: 14px;
    text-align: center;

    .title {
      color: #0f5983;
      display: inline-block;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
      position: relative;
      &:before,
      &:after {
        background: url("../img/game_arrow.svg") center / contain no-repeat;
        content: "";
        height: 20px;
        width: 50px;
        position: absolute;
        left: -65px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:after {
        left: unset;
        right: -65px;
        transform: translateY(-50%) scale(-1);
      }
    }
    .game-icon-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .game-icon {
        flex-basis: calc(100% / 5);
        margin: 8px 0;
        position: relative;
        text-align: center;
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
        animation: enlarge 400ms cubic-bezier(0.4, 0, 0.2, 1);
        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            animation-delay: #{$i * 200}ms;
          }
        }
        &:hover {
          transform: scale(1.2);
        }

        a {
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
        }
        img {
          width: 80%;
        }
      }

      @keyframes enlarge {
        50% {
          transform: scale(1.2);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    .game-button-wrapper {
      margin-top: 10px;
      button {
        font-size: 14px;
        height: unset;
        padding: 5px 20px;
      }
    }
  }
}

/* 优势 */
.benefit-wrapper {
  margin: 50px 0;

  .main-wrapper {
    display: flex;
  }

  .benefit-container {
    background-image: linear-gradient(#dbecf9, #fff);
    border-radius: 12px;
    box-shadow: 0 5px #d6e9f8;
    color: #707070;
    flex: 1;
    margin-top: 70px;
    text-align: center;
    &:not(:last-child) {
      margin-right: 20px;
    }

    .img-wrapper {
      margin-top: -70px;
      img {
        width: 80%;
      }
    }

    .title {
      color: #19628b;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .row {
      margin-bottom: 10px;
      padding: 0 30px;
      text-align: justify;
      &.text-center {
        text-align: center;
      }

      .row-header {
        color: #4c4c4c;
        font-size: 19px;

        &.support {
          padding: 0 15px;
          position: relative;
          &:before,
          &:after {
            background: url("../img/benefit_quote_open.svg") center / contain no-repeat;
            content: "";
            height: 100%;
            width: 20px;
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
          }
          &:after {
            background: url("../img/benefit_quote_close.svg") center / contain no-repeat;
            left: unset;
            right: -10px;
          }
        }
      }
      p {
        font-size: 14px;
      }
    }

    &.service {
      .row {
        display: flex;
        align-items: center;
      }
      .badge {
        background: url("../img/benefit_service_badge.png") center / contain no-repeat;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        height: 70px;
        width: 70px;
        margin-right: 10px;
        span {
          font-size: 12px;
          font-weight: normal;
          padding-left: 3px;
        }
      }
      strong {
        color: #19628b;
      }
      .svc-number,
      .svc-bank {
        font-size: 20px;
        font-weight: bold;
      }
    }

    &.app {
      .row-header {
        color: #2e6186;
      }
      .button {
        margin-top: 10px;
        width: 50%;
      }
    }
  }
}

/* 合作伙伴 */
.partner-wrapper {
  background-image: linear-gradient(#fff, #dbecf9);
  color: #4c4c4c;
  font-size: 16px;
  margin-bottom: -15px;
  padding: 30px 0 60px;
  text-align: center;
  .pay-wrapper {
    margin-top: 30px;
    img {
      height: 40px;
      &:not(:last-child) {
        margin-right: 50px;
      }
    }
  }
}

/*Subpage Content starts from here*/

.subcontent {
  background-image: linear-gradient(#fff 80%, #e8f3fc);
  border: 1px solid #d0e4f4;
  box-shadow: 0 4px 12px #f2f2f2;
  display: block;
  .corner-img-top {
    display: flex;
    img:last-child {
      margin-left: auto;
      transform: scaleX(-1);
    }
  }
  .corner-img-bottom {
    display: flex;
    img {
      transform: scaleY(-1);
      &:last-child {
        margin-left: auto;
        transform: scale(-1);
      }
    }
  }
}

/*Form starts from here*/
.subpage.form {
  background: url(../img/form_bg.png) top center/contain no-repeat;
}

.form-no {
  width: 27px;
  height: 27px;
  border-radius: 50% !important;
  padding: 2px !important;
  display: inline-block;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  background-color: #19628b;
  color: #ffe94d;
  line-height: 23px;
  margin: 0 7px;
  font-size: 12pt;
  font-weight: 600;
  text-align: center;
}

.form-head {
  width: 85%;
  padding-top: 30px;
  margin: auto;
  font-size: 14pt;
  line-height: 70px;
  height: 70px;
  color: #0f546b;
  span {
    position: relative;
    padding: 0 23px 0 17px;
    box-sizing: border-box;
    i.fa-forward {
      padding-left: 60px;
      font-size: 10pt;
      vertical-align: middle;
    }
  }
}

.form-body {
  padding: 25px 0;
}

.form-row {
  font-size: 11pt;
  color: #333333;
  text-align: left;
  margin: auto;
  padding-bottom: 20px;
  width: 72%;
  height: 30px;
  position: relative;
  &.with-button {
    text-align: center;
    button {
      width: 130px;
    }
  }

  > span {
    &:first-child {
      width: 85px;
      position: relative;
      &:after {
        content: ":";
        position: absolute;
        right: 10px;
      }
    }
    position: relative;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    input {
      border: 2px solid #f1f1f1;
      outline: 0;
      height: 35px;
      line-height: 35px;
      width: 240px;
      padding: 0 5px;
      border-radius: 3px;
      box-sizing: border-box;
    }
  }
  .verify-code {
    right: 397px;
    position: absolute;
    top: 2px;
  }
}

.form-highlight {
  color: #e33513;
  font-weight: 200;
  font-size: 9pt;
}

.form-username {
  background-color: #fff;
  border-radius: 5px;
  margin: 5px -2px;
  position: relative;
  display: inline-block;
  height: 28px;
}

/* Faq page starts from here */
.subpage.faq {
  background: url(../img/faq_bg.png) top center/contain no-repeat;
  .flex-wrapper {
    display: flex;
    padding-left: 30px;

    .sub-menu {
      padding-top: 40px;
      width: 180px;
      a {
        background: linear-gradient(#fefefe, #eaf4fc);
        border: 1px solid #c4d5ea;
        border-radius: 8px;
        color: #19628b;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        font-size: 14px;
        height: 50px;
        &.active {
          background: linear-gradient(#549ac1, #286e94);
          border-color: #4283aa;
          color: #fff;
        }
      }
    }

    .pages {
      padding-top: 40px;
      width: calc(100% - 180px);
      .nav-right:not(.active) {
        display: none;
      }
    }
  }
}

/* Promo page starts from here */
.subpage.promocontent {
  background: url("../img/promo_bg.png") top center / contain no-repeat;

  .title {
    color: #2e6186;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  img {
    max-width: 100%;
  }

  .promo-panel {
    margin-bottom: 50px;
  }

  .promotitle {
    .promo-flex {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .title {
        color: #2e6186;
        font-size: 18px;
        font-weight: bold;
      }
      .date-created {
        color: #888;
        margin-left: auto;
      }
    }
    img {
      border-radius: 12px;
    }
  }

  .promocontent {
    display: none;
  }
}

/* App page starts from here */
.subpage.app {
  background: url("../img/app_bg.png") top center / contain no-repeat;

  .btn-blue {
    margin-top: 20px;
    width: 130px;
  }

  .app-content {
    display: flex;
    .download-box {
      background: url("../img/app_downloadbox_bg.png") top right / cover #fff no-repeat;
      border-radius: 12px;
      box-shadow: 0 4px 12px #e5e5e5;
      display: flex;
      align-items: center;
      flex: 1;
      overflow: hidden;
      padding: 10px 25px;
      position: relative;
      &:not(:last-child) {
        margin-right: 20px;
      }

      &:before {
        background-image: linear-gradient(#e8684c, #b7442b);
        content: "";
        height: 100%;
        width: 12px;
        position: absolute;
        left: 0;
        top: 0;
      }

      .download-desc {
        margin-left: 15px;
        strong {
          color: #19628b;
        }
        p {
          font-size: 14px;
          padding-top: 10px;
        }
      }
    }
  }
}

/* AffIndex page starts from here */
.subpage.aff {
  background: url(../img/aff_bg.png) top center/contain no-repeat;

  .aff-menu {
    border-radius: 12px 12px 0 0;
    display: flex;
    height: 50px;
    overflow: hidden;

    .aff-tab {
      background-image: linear-gradient(#fefefe, #f8f9fd);
      color: #19628b;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-weight: bold;
      position: relative;
      &.active {
        background-image: linear-gradient(#19628b, #4894be);
        color: #fff;
      }

      &:not(:first-child):before {
        box-shadow: 4px 0 5px #9e9b9b;
        content: "";
        height: 70%;
        width: 1px;
        pointer-events: none;
        position: absolute;
        left: 0;
      }
    }
  }

  .aff-pages {
    padding-top: 40px;

    .page {
      &:not(.active) {
        display: none;
      }

      .headline {
        display: flex;
        align-items: center;
        padding: 0 50px;
        margin-bottom: 40px;
        img {
          transform: translateZ(1px);
        }
        .headline-box {
          background: url("../img/aff_goldbar_bg.png") center / cover no-repeat;
          border-radius: 12px;
          color: #fff;
          padding: 25px 30px 25px 120px;
          margin-left: -100px;
          strong {
            font-size: 16px;
          }
        }
      }

      .aff-flex {
        display: flex;
        flex-wrap: wrap;
        .plan-box {
          border: 1px solid #c2ddf4;
          border-radius: 12px;
          box-shadow: 0 5px #d6e9f8;
          box-sizing: border-box;
          color: #707070;
          flex-basis: calc(50% - 20px);
          margin-bottom: 30px;
          padding: 50px 15px 20px;
          position: relative;
          &:nth-child(odd) {
            margin-right: 20px;
          }

          .title {
            background: url("../img/aff_boxtitle.png") center / cover no-repeat;
            color: #fff;
            display: inline-block;
            font-size: 18px;
            line-height: 40px;
            height: 44px;
            width: 150px;
            position: absolute;
            left: 20px;
            top: -5px;
            text-align: center;
          }

          ol {
            padding-left: 20px;
            margin: 0;
          }
        }
      }
    }
  }
}

/*Footer starts from here*/
.footer {
  margin-top: 15px;
  text-align: center;

  .link-wrapper {
    background-image: linear-gradient(#19628b, #4894be);
    padding: 30px 0;

    a {
      color: #c8dfeb;
      font-size: 14px;
      margin: 0 30px;
      position: relative;
      &:not(:first-child):before {
        background-color: #5291b2;
        content: "";
        height: 100%;
        width: 2px;
        pointer-events: none;
        position: absolute;
        left: -30px;
        top: 0;
      }
    }

    .main-wrapper {
      position: relative;
    }

    .opening-hours {
      border-radius: 0 0 8px 8px;
      box-shadow: 0 2px 5px #185b83;
      position: absolute;
      top: -30px;
      left: 0;
      overflow: hidden;
      .opening-img {
        background-image: linear-gradient(#fbe5b7, #fdf2db);
        padding-top: 8px;
        img {
          height: 78px;
        }
      }
      .opening-desc {
        background-image: linear-gradient(#b7442b, #e8684c);
        color: #fff;
        font-size: 12px;
        margin-top: -10px;
        padding: 10px;
      }
    }
  }

  .browser-wrapper {
    background-image: linear-gradient(#19628b, #4894be);
    color: #7fb4d2;
    font-size: 12px;
    padding: 30px 0;

    .title {
      font-size: 14px;
    }

    .browser-img {
      margin: 20px 0;
      img {
        height: 35px;
        mix-blend-mode: luminosity;
      }
    }

    .pagcor-wrapper {
      display: flex;
      align-items: center;
      margin: 20px auto;
      width: 70%;
      img {
        margin-right: 20px;
      }
      p {
        text-align: left;
      }
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

/*Subpage content starts from here*/

.subpage {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: calc(100vh - 40px - 85px - 80px - 236px);
}

.cont-head {
  width: 90%;
  margin: auto;
  font-size: 18pt;
  color: #2e6186;
  font-weight: 600;
}

.cont-body {
  padding: 40px 0;
  width: 90%;
  margin: auto;
  text-align: left;
  font-size: 10pt;
  color: #525252;
  min-height: 280px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    bottom: 10px;
  }
  .que {
    font-weight: 600;
    font-size: 11pt;
  }
  .anw {
    margin-bottom: 18px;
  }
}

.left-sidebar {
  background-color: #fef9ed;
  border-radius: 0 12px 12px 0;
  width: 129px;
  padding-bottom: 10px;
  position: fixed;
  left: 0;
  top: 180px;
  z-index: 9;
  text-align: center;

  .link-daohang {
    display: block;
    margin-top: -50px;

    .daohang-text {
      color: #dd563a;
      font-size: 18px;
      font-weight: bold;
      margin-top: -5px;
      text-align: center;
    }
  }

  .appqr-wrapper {
    z-index: 99999;
    width: 100%;
    .appqrcode {
      padding-top: 10px;
      box-sizing: border-box;
      img {
        width: 98px;
        height: 98px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 6px;
      }
    }
    .text {
      color: #333333;
      text-align: center;
      font-size: 16px;
      line-height: 21px;
      margin-top: 7px;
    }
  }

  .appdlurl {
    color: #dd563a;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin: 2px 5px;
    letter-spacing: -0.5px;
    word-break: break-all;
  }
}

.appqr img {
  background-color: #fff;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  top: 138px;
  position: absolute;
  left: 15px;
}

.right-sidebar {
  position: fixed;
  right: 5px;
  top: 180px;
  z-index: 9;

  .sidebar-item {
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    margin-bottom: 5px;
    position: relative;
    &:before {
      border: 3px solid transparent;
      border-radius: 50%;
      content: "";
      pointer-events: none;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      transition: all 200ms ease-in-out;
    }

    &:hover {
      .sidebar-popup {
        transform: scale(1);
      }
    }

    .icon {
      border-radius: 50%;
      width: 100%;
    }

    .sidebar-popup {
      background-color: #fef9ed;
      border-radius: 8px;
      color: #333;
      padding: 10px;
      position: absolute;
      top: 0;
      right: 60px;
      transform: scale(0);
      transform-origin: top right;
      transition: all 200ms ease-in-out;
      > div {
        word-break: break-all;
      }
    }

    &.morse {
      background-color: #323233;
      &:hover {
        &:before {
          border-color: #000;
        }
      }
    }
    &.qq {
      background-color: #0197cb;
      &:hover {
        &:before {
          border-color: #047095;
        }
      }
    }
    &.wechat {
      background-color: #009901;
      &:hover {
        &:before {
          border-color: #036804;
        }
      }
    }
    &.chatroom {
      background-color: #fd8469;
      &:hover {
        &:before {
          border-color: #db3a16;
        }
      }
    }
    &.livechat {
      background-color: #ffae02;
      &:hover {
        &:before {
          border-color: #a77200;
        }
      }
    }
    &.kefuapp {
      &:hover {
        &:before {
          border-color: #795548;
        }
      }
    }
    &.mac {
      background-color: #d5208a;
      &:hover {
        &:before {
          border-color: #8b0754;
        }
      }
    }
    &.windows {
      background-color: #7225c1;
      &:hover {
        &:before {
          border-color: #330f57;
        }
      }
    }
  }
}

/*Jssor Slider*/

/* jssor slider loading skin spin css */

.jssorb032 {
  position: absolute;
  .i {
    position: absolute;
    cursor: pointer;
    .b {
      fill: rgba(255, 255, 255, 0.8);
    }
    &:hover .b {
      fill: #3f0c14;
      fill-opacity: 1;
    }
  }
  .iav .b {
    fill: #3f0c14;
    fill-opacity: 1;
  }
  .i.idn {
    opacity: 0.3;
  }
}

.jssora051 {
  display: block;
  position: absolute;
  cursor: pointer;
  .a {
    fill: none;
    stroke: #fff;
    stroke-width: 360;
    stroke-miterlimit: 10;
  }
  &:hover {
    opacity: 0.8;
  }
  &.jssora051dn {
    opacity: 0.5;
  }
  &.jssora051ds {
    opacity: 0.3;
    pointer-events: none;
  }
}

.cm_information {
  a {
    color: #2e6186;
  }
  table {
    background-color: transparent;
    td {
      background-color: transparent;
    }
  }
}

.wxqr {
  height: auto;
  float: right;
  display: inline-block;
}

.hide {
  display: none !important;
}

#mobileOtpCode {
  top: 17px !important;
}

.cooperation-list {
  display: flex;

  > div {
    text-align: left;
  }

  .cooperation-title {
    flex-basis: 150px;
    font-weight: bold;

    &:after {
      content: ":";
    }
  }

  .cooperation-qrcode {
    width: 150px;
    background: white;
  }
}
